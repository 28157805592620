html {
  overflow-x: hidden;
  font-family: $title; }
body {
  font-family: $paragraph;
  margin: 0;
  overflow-x: hidden;
  // font-family: $title
  &.nonscroll {
    overflow: hidden;
    height: 100%;
 } }    // background-color: black
::-moz-selection {
  background: rgba(0,0,0,.7);
  color: white; }
::selection {
  background: rgba(0,0,0,.7);
  color: white; }
h1, h2, h3, h4 {
  font-family: $title;
  &.big-light {
    font-size: 2.8em;
    max-width: 568px;
    line-height: 1.3;
    font-weight: 100;
    color: rgba(255,255,255,.8);
    text-align: center;
    @media screen and (max-width: 681px) {
      font-size: 2em;
      max-width: 80%; } } }
h2.product-title {
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.25em;
  font-size: 1.5em;
  margin: 0; }
input {
  outline: none; }
.underlined {
  text-decoration: underline; }
.relative {
  position: relative; }
.icon-select {
  position: absolute;
  pointer-events: none;
  top: 16px;
  right: 20px;
  &.t {
    top: 41px; } }
span.label {
  width: 250px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  &.t {
    position: relative;
    top: 15px; } }
input[type="number"] {
  padding-right: 20px; }
textarea {
  resize: none; }
input[type="text"], input[type="number"], select.styled, textarea, input[type="password"] {
  width: 100%;
  padding: 15px 25px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px #f8f8f8;
  margin: 25px 0;
  font-size: 14px;
  margin-bottom: 15px;
  font-family: $paragraph;
  background: #f8f8f8;
  outline: none;
  &:focus {
    border-color: #f2f2f2; }
  &.mt {
    margin-top: 0; } }
select.styled {
  appearance: none; }
ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }
a {
  text-decoration: none; }
.app {
  min-height: 100vh; }
.btn {
  padding: 14px 50px;
  text-transform: uppercase;
  color: $white;
  font-family: $title;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-radius: 3px;
  white-space: nowrap;
  transition: all .2s ease;
  text-align: center;
  cursor: pointer;
  &.btn-small {
    border: solid 1px #ddd;
    color: $text;
    transition: none;
    padding: 5px 8px;
    font-size: 11px;
    text-transform: none;
    letter-spacing: 0;
    border-radius: 4px;
    font-weight: 400;
    background: $white;
    &:hover {
      color: $primary;
      border-color: $primary; }
    &.hasIcon {
      padding-left: 5px; }
    i {
      font-size: 9px;
      position: relative;
      margin-right: 5px;
      top: .5px;
      &.fas {
        top: -1px;
        @media screen and (max-width: 992px) {
          height: 26px;
          top: 3px; } } } }
  &.btn-primary {
    background: $black;
    width: 100%;
    display: block;
    padding: 14px;
    box-sizing: border-box;
    &:hover {
      background: $primary; } }
  &.btn-line {
    border: solid 1px $white;
    background: transparent;
    &.black {
      color: $black;
      border: solid 1px $black;
      &:hover {
        color: white; } }
    &:hover {
      background-color: $primary;
      border-color: $primary; } } }
.switcher {
  width: 50px;
  height: 20px;
  position: relative;
  border: solid 1px #ddd;
  border-radius: 20px;
  box-shadow: inset 0px 0px 5px rgba(0,0,0,.2);
  overflow: hidden;
  .style {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 50%;
    background: $white;
    border: solid 1px #ddd;
    transition: all .175s ease;
    &::before, &::after {
      font-size: 13px;
      color: $text;
      top: 0;
      position: absolute; }
    &::before {
      content: attr(val1);
      margin-left: 20px; }
    &::after {
      content: attr(val2);
      margin-left: -20px; } }
  input[type="checkbox"] {
    appearance: none;
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    &:checked ~ .style {
      left: 31px; } } }

.radio {
  height: 20px;
  width: 20px;
  position: relative;
  input[type="radio"] {
    appearance: none;
    height: 20px;
    width: 20px;
    margin: 0;
    cursor: pointer; }
  .style {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: solid 1px #ddd;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none; }
  input[type="radio"]:checked ~ .style {
    background: $text;
    border-color: $text;
    &::after {
      content: "\e019";
      font-family: "javavalley";
      color: $white;
      position: relative;
      top: 2px;
      left: 4px;
      font-size: 10px; } } }
.skeleton {
  min-height: 10px;
  min-width: 10px;
  background: linear-gradient(90deg,hsla(0,0%,74.5%,.2) 25%,hsla(0,0%,50.6%,.24) 37%,hsla(0,0%,74.5%,.2) 63%);
  background-size: 400% 100%;
  border-radius: 4px;
  display: inline-block;
  animation: ant-skeleton-loading 1.4s ease infinite; }

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }
.top {
  margin-top: 105px;
  @media screen and (max-width: 992px) {
    margin-top: 80px; } }
.top-2 {
  margin-top: 90px;
  @media screen and (max-width: 992px) {
    margin-top: 60px; } }
.separator {
  .line-gradient {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(left,rgba(255,255,255,0) 0,rgba(0,0,0,.22) 50%,rgba(255,255,255,.29) 100%);
    background: -webkit-gradient(left top,right top,color-stop(0,rgba(255,255,255,0)),color-stop(50%,rgba(0,0,0,.22)),color-stop(100%,rgba(255,255,255,.29)));
    background: -webkit-linear-gradient(left,rgba(255,255,255,0) 0,rgba(0,0,0,.22) 50%,rgba(255,255,255,.29) 100%);
    background: -o-linear-gradient(left,rgba(255,255,255,0) 0,rgba(0,0,0,.22) 50%,rgba(255,255,255,.29) 100%);
    background: -ms-linear-gradient(left,rgba(255,255,255,0) 0,rgba(0,0,0,.22) 50%,rgba(255,255,255,.29) 100%);
    background: linear-gradient(to right,rgba(255,255,255,0) 0,rgba(0,0,0,.22) 50%,rgba(255,255,255,.29) 100%); } }

ul.tools-action {
  padding: 20px 0 15px;
  span {
    font-family: $title;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: .2px;
    visibility: hidden;
    margin-top: 0px; }
  .relative {
    position: relative; }
  span.count {
    position: absolute;
    visibility: visible;
    top: -8px;
    right: -10px;
    background: #dc4343;
    padding: 1px 6px;
    border-radius: 18px;
    color: $white; }
  a {
    color: $neutral;
    &:hover {
      color: $primary;
      span {
        visibility: visible; } } }
  i.icon {
    font-size: 24px; }
  &.new {
    li:first-child {
      margin-right: 20px; }
    a {
      position: relative; }
    span {
      position: absolute;
      bottom: -15px; } } }
.selectBox {
  position: relative;
  select {
    width: 100%;
    padding: 12px;
    outline: none;
    border-radius: 4px;
    border-color: #ddd;
    appearance: none;
    font-family: $title; }
  i.icon {
    position: absolute;
    top: 12px;
    right: 12px;
    pointer-events: none; } }

.col12 {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  font-family: $paragraph; }

.col12.full {
  width: 100%; }

dl {
  font-size: 12px; }

dt {
  float: left;
  width: 100px;
  line-height: 24px;
  color: #333;
  font-weight: 400; }

dd {
  padding-left: 110px;
  line-height: 24px;
  color: #555;
  font-weight: 400;
  font-size: 13px; }

span.sub-price, h4.sub-price {
  font-family: $title;
  margin-left: 20px;
  color: #333; }

dd, dd, dt, dt {
  line-height: 20px; }

.loader-global {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.85);
  transition: all .125s ease;
  opacity: 0;
  pointer-events: none;
  &.on {
    opacity: 1; }
  p {
    color: $text;
    font-size: 13px; } }

img.bg-blur {
  width: 100vw;
  height: calc(100vh - 5px);
  object-fit: cover;
  filter: blur(5px); }

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
  &:hover {
    color: #fff;
    background-color: #564d55;
    border-color: #564d55; } }
.btn-link-black {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  padding: 24px 16px;
  font-weight: 300;
  transition: none;
  &.btn-link-small {
    padding: 8px 4px;
    margin-right: 8px; }
  &:hover {
    color: #000;
    background-color: transparent;
    border-color: transparent;
 }    // font-weight: 500
  &.active {
    border-bottom: 4px solid #000000;
    font-weight: 500; } }
.btn-group {
  .btn-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &.no-border-right {
      border-right: 0; } }
  .btn-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    i {
      font-size: 16px;
      position: relative;
      top: 3px; } }
  .media-card {
    border-radius: inherit;
    overflow: inherit;
    width: inherit;
    height: inherit;
    margin: inherit;
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .btn-left {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &.no-border-right {
        border-right: solid 1px #fff; } }
    .btn-right {
      margin-top: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; } } }
.btn.btn-ghost-black {
  color: #000;
  background-color: rgba(0,0,0,0);
  border-color: #000;
  margin: 15px auto;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  &.btn-small {
    padding: 5px 20px 4px;
    margin-top: 15px;
    font-size: .65em; }
  &:hover {
    color: #fff;
    background-color: rgba(0,0,0,1);
    border-color: #000; } }
