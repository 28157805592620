.filter-catalog {
  padding: 10px 0;
  .view {
    display: none; }
  .boxSearch {
    margin-right: 25px;
    position: relative;
    padding: 10px 0;
    color: #282828;
    top: 5px;
    i {
      color: #282828; } }
  .sort-by {
    // margin-right: 20px
    position: relative;
    padding: 10px 0;
    &.--filter {
      margin-right: 25px; }

    @media screen and (max-width: 992px) {
      &.--filter {
        margin-right: 10px; }
      &::before {
        content: '';
        position: absolute;
        width: 300vw;
        height: 200vh;
        top: -100vh;
        left: -100vw;
        background: rgba(0,0,0,.6);
        z-index: 230;
        pointer-events: none;
        opacity: 0;
        transition: opacity .5s ease; } }
    @media screen and (max-width: 600px) {
      &.--filter {
        margin-right: 0;
        margin-top: 20px; } }
    &:hover {
      .sort-main {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto; }
      &::before {
        opacity: 1; } }
    .sort-main {
      position: absolute;
      width: 240px;
      background: $white;
      border: solid 1px #fafafa;
      box-shadow: 1px 5px 10px rgba(0,0,0,0.1);
      padding: 10px 20px;
      box-sizing: border-box;
      right: -100%;
      z-index: 2;
      margin-top: 12px;
      border-radius: 4px;
      opacity: 0;
      transform: translateY(-20px);
      transition: all .325s ease;
      pointer-events: none;
      &::before {
        content: '';
        height: 12px;
        position: absolute;
        width: 100%;
        right: 0;
        top: -12px; }
      li {
        padding: 4px 0; }
      span {
        font-weight: 300;
        color: $text;
        font-size: 14px;
        margin-left: 10px;
        letter-spacing: .5px;
        &:hover {
          color: $black; } }
      @media screen and (max-width: 992px) {
        left: 0;
        width: 100%;
        top: 100%;
        z-index: 231;
        padding: 0;
        border: none;
        border-radius: 6px;
        .radio {
          display: none; }
        li {
          padding: 15px;
          border-bottom: solid 1px #ddd;
          &:last-child {
            border-bottom: none; } }
        span {
          font-size: 15px;
          font-weight: 500;
          &::before {
            content: "SORT BY : ";
            color: $neutral;
            font-weight: 400;
            letter-spacing: 1px; } } } }
    a {
      font-family: $title;
      font-size: 13px;
      color: $black;
      font-weight: 600;
      letter-spacing: 1.5px; }
    i.icon {
      position: relative;
      top: 2px;
      font-size: 14px;
      margin: 0 2px; } }
  @media screen and (max-width: 1024px) {
    padding: 20px; }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    .main-filter {
      width: 100%;
      ul {
        width: 100%; } }
    li.view {
      display: none; }

    .sort-by, .boxSearch {
      margin-right: 0;
      width: 100%;
      margin-top: 15px;
      display: block;
      padding: 14px;
      border-bottom: solid 1px #ddd;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      i.icon {
        position: absolute;
        top: 18px;
        right: 18px;
        color: #282828; } }
    .boxSearch {
      i.icon {
        top: 18px;
        right: 32px; } } }
  @media screen and (max-width: 600px) {
    .main-filter {
      ul {
        flex-direction: column; } }
    .sort-by {
      width: 95%;
      margin-top: 10px; } }
  &.filter-catalog-swatches {
    padding: 0;
    .boxInputSearchMaterial {
      display: none;
      border-bottom: 2px solid #282828;
      &.active {
        display: flex; } }
    .btnSearchMaterial {
      display: none;
      &.active {
        display: block; }
      @media screen and (max-width: 992px) {
        width: 100%;
        height: 28px;
        color: #282828; } }
    .search-material {
      margin: 0;
      background-color: #fff;
      border: 0; } } }
.boxIcon {
  position: relative;
  cursor: pointer;
  height: 21px;
  width: 21px;
  span {
    width: 9px;
    height: 9px;
    background: $black;
    position: absolute;
    top: 0;
    right: 0;
    &:nth-child(2) {
      right: 12px; }
    &:nth-child(3) {
      top: 12px; }
    &:nth-child(4) {
      right: 12px;
      top: 12px; } } }
