.boxVideo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0,0,0,.5);
  .header-menu, .head-menu {
    position: fixed;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    z-index: 99;
    padding: 5px 20px;
    background-color: rgba(0, 0, 0, 0.5);
    .head-title-video {
      padding: 5px 0;
      .btncls-video {
        position: relative;
        top: 5px;
        font-size: 24px; }
      span {
        color: $white;
        letter-spacing: 0.5px;
        font-family: $title;
        font-size: 14px; } }
    .btncls-video {
      position: relative;
      top: 5px;
      font-size: 24px;
      color: $white; } }
  video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 768px;
    max-height: 435px;
    margin: auto;
    border-radius: 8px;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.4);
    background-color: $black; } }
