.folder {

  .content {
    @media screen and (max-width: 992px) {
      &::before {
        content: '';
        position: fixed;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,.75);
        top: 0;
        z-index: 200;
        opacity: 0;
        pointer-events: none;
        transition: opacity .325s ease; }
      &.overlay::before {
        opacity: 1;
        pointer-events: auto; } } }
  .sidebar {
    background: #f8f8f8;
    width: 225px;
    min-height: calc(100vh - 83px);
    li.active {
      border-left: solid 5px $primary;
      a {
        background: $white;
        @media screen and (max-width: 992px) {
          background: #f8f8f8; } } }
    .toggleSmallFolder {
      color: #2f2f2f;
      font-size: 16px;
      position: relative;
      right: 0;
      background: $white;
      padding: 12px 20px;
      font-weight: 600;
      display: none;
      border-bottom: solid 1px #ddd;
      &:hover {
        background: $white; }
      i.icon {
        font-size: 17px;
        top: 0px;
        transform: rotateZ(180deg);
        transition: transform .2s ease;
        transition-delay: .5s; } }
    @media screen and (max-width: 992px) {
      .toggleSmallFolder {
        display: flex; }
      .scroller {
        overflow-y: auto;
        max-height: 70vh;
        background: $white; } }
    .category {
      margin: 20px 0; }
    .category-2 {
      margin: 0;
      // padding: 20px 0
      background: #f2f2f2;
      li {
        text-align: center; }
      a.btn {
        padding: 20px 0;
        background: $primary;
        border-radius: 0;
        color: $white;
        font-weight: 500;
        &:hover {
          background: #907f64; } } }
    h5 {
      font-family: $title;
      margin: 0;
      margin-bottom: 5px;
      margin-left: 15px;
      color: #555; }
    a {
      color: #555;
      font-size: 14px;
      font-family: $title;
      padding: 10px 20px;
      display: block;
      letter-spacing: .5px;
      font-weight: 300;
      &:hover {
        background: #dbdbdb;
        color: #282828; } }
    i.material-icons {
      font-size: 16px;
      position: relative;
      top: 3px;
      left: -1px; }
    i.icon {
      position: relative;
      top: 1px;
      font-size: 12px;
      margin-right: 5px; }
    @media screen and (max-width: 1440px) {
      width: 240px; }
    @media screen and (max-width: 992px) {
      position: fixed;
      z-index: 201;
      background: #fff;
      width: 100%;
      bottom: 0;
      min-height: auto;
      height: 70vh;
      overflow: hidden;
      border-radius: 8px 8px 0 0;
      transition: all .75s cubic-bezier(0.73, 0.12, 0.25, 1);
      transform: translateY(calc(100% - 44px));
      box-shadow: 0px -3px 9px rgba(0, 0, 0, 0.11);
      &.on {
        transform: translateY(0);
        .toggleSmallFolder i.icon {
          transform: rotateZ(0deg);
          top: 3px; } }

      .category {
        margin: 0;
        padding: 20px 0 0;
        &:last-child {
          padding-bottom: 60px; } }
      a {
        font-size: 15px;
        padding: 12px 25px;
        font-weight: 300; }
      h5 {
        font-size: 16px;
        margin-left: 20px;
        margin-bottom: 10px; } } }
  .header-folder {
    padding: 10px 0;
    background: #f2f2f2;
    border-bottom: solid 1px #ddd;
    .h-0 {
      width: 230px;
      padding-left: 20px;
      @media screen and (max-width: 992px) {
        width: auto;
        padding-left: 0;
        a {
          background: none;
          border: none;
          max-width: 70px;
          white-space: normal;
          i {
            font-size: 22px;
            margin-right: 0;
            margin-bottom: 5px;
            display: block; } } } }
    .h-1 {

      a {
        margin: 0 5px; }
      @media screen and (max-width: 992px) {
        overflow-x: auto;
        width: 100%;
        justify-content: flex-end;
        a {
          background: none;
          border: none;
          max-width: 70px;
          white-space: normal;
          i {
            font-size: 22px;
            margin-right: 0;
            margin-bottom: 5px;
            display: block; } } } }
    & > .h- {
      padding: 20px 0 0;
      @media screen and (max-width: 681px) {
        justify-content: space-between;
        align-items: flex-start; } }
    .title {
      color: #2f2f2f;
      span {
        font-family: $title;
        font-size: 15px; }
      i.icon {
        // margin-right: 10px
        font-size: 16px;
        position: relative;
        top: 2px; } } }
  .main-content {
    width: calc(100vw - 225px);
    height: calc(100vh - 83px);
    margin-right: -17px;
    overflow-y: auto;
    @media screen and (max-width: 992px) {
      width: 100vw; }
    ul.list-product > li {
      padding: 24px 15px;
      &:nth-child(2n) {
        background: #fcfcfc; } }

    .images {
      max-width: 300px;
      align-items: flex-start;
      img {
        width: 82%;
        object-fit: contain;
        mix-blend-mode: multiply; } }
    @media screen and (max-width: 1024px) {
      .images {
        max-width: 360px;
        img {
          width: 70%; } } }
    @media screen and (max-width: 992px) {
      ul.list-product > li {
        flex-direction: column; }
      .images {
        max-width: none;
        display: flex;
        justify-content: center;
        padding: 0 20px;
        img {
          max-width: 400px;
          max-height: 250px;
          width: 100%;
          height: 100%;
          object-fit: contain; } } } }
  .information {
    margin-left: 30px;
    min-width: 350px;
    ul {
      margin-top: 20px;
      ul.list-detail {
        margin-top: 0;
        li {
          padding: 10px 0; } } }
    li {
      padding: 5px 0;
      position: relative;
      &.note {
        padding-top: 15px;
        margin-top: 15px;
        border-top: solid 1px #ddd; }
      .product-detail {
        box-shadow: 0px 0px 10px rgba(0,0,0,.2);
        padding: 8px 20px;
        padding-top: 4px;
        position: absolute;
        z-index: 2;
        background: $white;
        width: calc(100% - 36px);
        top: 100%;
        pointer-events: none;
        opacity: 0;
        transform: translateY(50px);
        transition: all .2s ease;
        &.on {
          opacity: 1;
          transform: translateY(0px); } }

      .content {
        padding: 0; } }
    h5 {
      font-family: $title;
      margin: 3px 0;
      color: #555;
      font-weight: 500; }
    .g-0 {
      width: 350px;
      // max-width: 350px
      i.icon {
        position: relative;
        top: 2px;
        margin-left: 5px; } }
    .tools-action {
      margin: 0;
      padding: 10px 0;
      span {
        text-transform: none;
        letter-spacing: 0; } }
    p, a {
      margin: 0;
      color: $text;
      font-family: $title;
      font-size: 14px;
      font-weight: 300; }
    p {
      max-width: 18ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @media screen and (max-width: 681px) {
        max-width: 13ch; }
      &.special {
        color: $primary;
        font-weight: 500;
        font-size: 16px; }
      &.long-text {
        max-width: 350px;
        white-space: inherit; } }
    a:hover {
      color: $black; }

    @media screen and (max-width: 992px) {
      margin: 0;
      margin-top: 20px;
      padding: 0 20px;
      .g-0 {
        width: 100%; } } } }
.no-product {
  height: 85%;
  box-sizing: border-box;
  img {
    max-width: 200px; }
  h2 {
    margin-bottom: 0; }
  p {
    color: $text;
    margin: 5px 0 30px; }
  @media screen and (max-width: 681px) {
    padding: 0 20px;
    img {
      max-width: 100px;
      margin-bottom: 10px; }
    h2, p {}
    text-align: center;
    h2 {
      font-size: 17px; }
    p {
      font-size: 14px;
      margin-top: 10px; } } }
