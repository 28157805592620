.f {
  display: flex; }
.f-r {
  flex-direction: row; }
.f-c {
  flex-direction: column; }
.mdl {
  align-items: center; }
.f-end {
  align-items: flex-end; }
.f-ctr {
  justify-content: center; }
.f-btw {
  justify-content: space-between; }
.f-ar {
  justify-content: space-around; }
.f-rht {
  justify-content: flex-end; }
.f-w {
  flex-wrap: wrap; }
@media screen and (max-width: 768px) {
  .f-c-mobile {
    flex-direction: column; }
  .f-btw-mobile {
    justify-content: space-between; }
  .f-w-mobile {
    flex-wrap: wrap; }
  .f-ctr-mobile {
    justify-content: center; } }

