.media-wrapper {
  background: #ECECEC;
  padding: 46px 0;
  min-height: 60vh; }
.media-card-wrapper {
  width: calc(100% + 16px);
  margin: -8px; }
.media-card {
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  width: 345px;
  height: 250px;
  margin: 8px;
  cursor: pointer;
  &.inherit {
    background: inherit;
    width: inherit;
    height: inherit; }
  &.social {
    height: 338px;
    img {
      max-height: 209px; } }
  .text {
    padding: 20px;
    p:not(.sm) {
      font-size: 14px;
      line-height: 1.5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; } }
  .social-header {
    margin-bottom: 20px; }
  .avatar {
    height: 46px;
    width: 46px;
    object-fit: cover;
    border-radius: 50%;
    border: solid 1px #eaeaea;
    margin-right: 14px; }
  img {
    width: 100%;
    max-height: 194px;
    object-fit: cover; }
  @media screen and (min-width: 1920px) {
    height: 270px;
    img {
      max-height: 220px; } }
  @media screen and (min-width: 1441px) {
    width: calc(100% / 4 - 16px); }
  @media screen and (max-width: 768px) {
    width: calc(100% / 2 - 16px); }
  @media screen and (max-width: 645px) {
    height: 220px; }
  @media screen and (max-width: 475px) {
    width: 100%;
    height: 250px; } }
