p {
  font-family: $title;
  font-weight: 400;
  margin: 0;
  &.centered {
    text-align: center; }
  &.sm {
    font-size: 12px; }
  &.l {
    font-weight: 300; } }
div {
  box-sizing: border-box; }
.fullshow {
  height: 100vh;
  position: relative;
  overflow: hidden;
  .full-content {
    opacity: 0;
    transition: all .75s ease; }
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $black;
    &.hasPattern::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url("../img/pattern.png");
      z-index: 2; } }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1; }
  video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0; } }
