header {
  position: fixed;
  width: 100%;
  z-index: 210;
  opacity: 0;
  transition: all .75s ease;
  &::before {
    content: "";
    background: linear-gradient(rgba(0,0,0,.85),transparent);
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity .7s ease; }
  .search-box {
    position: absolute;
    top: 0;
    right: 9px;
    width: 450px;
    padding: 8px 0;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease;
    &.on {
      opacity: 1;
      pointer-events: auto; }
    & > i.icon {
      position: absolute;
      font-size: 19px;
      top: 16px;
      left: -22px;
      color: white; }
    input {
      padding: 10px;
      padding-right: 38px;
      background: none;
      margin: 0;
      border: none;
      border-radius: 0;
      color: white; }
    button {
      position: absolute;
      top: 16px;
      right: -5px;
      font-size: 19px;
      background: none;
      border: none;
      color: white;
      z-index: 9;
      cursor: pointer;
      outline: none; }
    @media screen and (max-width: 1440px) {
      width: 270px; }
    @media screen and (max-width: 992px) {
      top: 3px;
      // right: -30px
      width: 220px; }
    @media screen and (max-width: 360px) {
      width: 170px; } }
  .box {
    position: relative; }
  .logo-box {
    background-color: $white;
    width: 130px;
    height: 90px;
    img {
      // max-width: 105px
      // height: 50px
      width: 100%; } }
  .showroom-list {
    height: 25px;
    background-color: $black;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    width: 100%;
    span {
      font-size: 9px;
      color: $white;
      letter-spacing: .5px;
      font-family: $title;
      text-align: center; } }
  &.black {
    background: $black;
    opacity: 1;
    &::before {
      display: none; }
    .main-menu {
 } }      // border-bottom: none

  .main-menu {
    padding-left: 24px;
    border-bottom: solid 1px rgba(203,203,203,.24);
    &:not(.sub-menu) {
      height: 52px; }
    &.sub-menu {
      height: 38px;
      border-bottom: none;
      @media screen and (max-width: 992px) {
        opacity: 0;
        pointer-events: none;
        &.active {
          opacity: 1;
          pointer-events: auto; }
        ul.menu {
          a {
            color: #000; } } }
      ul.menu {
        display: none;
        & > li {
          @media screen and (max-width: 992px) {
            height: 42px!important;
            &.on > a {
              color: #fff; }
            &:hover {
              a {
                color: #000; } }
            &.on {
              & > a {
                color: #fff !important; } } } }
        a {
          font-weight: 300;
          text-transform: none; }
        &.active {
          display: flex;
          @media screen and (max-width: 992px) {
            background: #e2e2e2;
            top: 100px; } } } }
    ul.menu > li:hover {
      .inner-menu {
        opacity: 1;
        pointer-events: auto; }
      & > a {
        font-weight: 600;
        color: $white; } }
    .preview-video {
      width: 35%;
      .thumbnail {
        position: relative;
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          height: 180px;
          object-fit: cover; } }
      .play-film {
        position: absolute;
        top: calc(50% - 29.5px);
        left: calc(50% - 42px); }
      i {
        font-size: 36px;
        color: white; }
      span {
        text-transform: uppercase;
        font-family: $title;
        font-size: 13px;
        color: $white;
        font-weight: 500; } }
    .inner-menu {
      position: absolute;
      top: 90px;
      left: 0;
      width: 100%;
      background: $white;
      height: 230px;
      opacity: 0;
      pointer-events: none;
      box-shadow: 0px 5px 10px rgba(0,0,0,.1);
 }      // transition: all .15s ease
    .submenu {
      margin-left: 154px;
      margin-top: 25px;
      & > .f.f-c {
        width: 65%; }
      a.select-all {
        color: $black;
        margin-bottom: 5px;
        display: inline-block; }
      li {
        padding: 4px 0; }
      a {
        color: $text;
        text-transform: none;
        letter-spacing: 0px;
        font-weight: 300;
        padding-left: 0;
        outline: none;
        &:hover {
          color: $black;
          font-weight: 300; } } }
    a {
      padding: 0 15px;
      font-family: $title;
      text-transform: uppercase;
      color: rgba(255,255,255,.75);
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 300;
      transition: all .2s ease;

      &:hover, &.active {
        color: $white;
        font-weight: 600; } }
    &.sub-menu {
      ul.menu {
        & > li {
          height: 38px; } }

      a {
        font-size: 12px; } }
    ul.menu {
      li:first-child {
        a {
          padding-left: 0; } } } }
  .tools {
    #user-icon-text {
      font-size: 13px;
      position: absolute;
      right: 120%;
      top: 1px;
      text-transform: none;
      white-space: nowrap;
      transform: translateX(-50px);
      opacity: 0;
      transition: all .2s ease;
      @media screen and (max-width: 992px) {
        pointer-events: none;
        display: none; } }
    a {
      padding: 0 9px;
      font-size: 19px;
      position: relative;
      i.fas, i.far {
        font-size: 20px;
        position: relative;
        top: -1px; }
      &:hover {
        font-weight: 300;
        #user-icon-text {
          transform: translateX(0);
          opacity: 1; } } }
    li.menu-item:nth-child(3) {
      & > a {
        padding-right: 0; } }
    .burger-menu {
      position: relative;
      display: none;
      a {
        width: 22px;
        height: 16px;
        &:hover {
          span {
            background: $white; } } }
      span {
        height: 2px;
        width: 21px;
        position: absolute;
        background: rgba($white, .75);
        top: 0;

        &:nth-child(2) {
          top: 6px; }
        &:nth-child(3) {
          top: 12px; } } } }
  @media screen and (max-width: 992px) {
    background: $black;
    opacity: 1;
    height: 60px;
    .logo-box {
      background-color: $white;
      height: 60px;
      width: 100px;
      margin-left: -20px;
      // padding: 0 5px
      padding: 0 0px; }
    .showroom-list {
      display: none; }
    &::before {
      display: none; }
    .main-menu:not(.sub-menu) {
      height: 60px; }
    .main-menu {
      border-bottom: none;
      padding-left: 0;
      // &.sub-menu
      //   .submenu
      //     a
      //       color: #000!important
      .submenu {
        margin-left: 0;
        li {
          padding: 8px 0; }
        & > .f.f-c {
          width: 100%;
          .f.f-r {
            flex-direction: column;
            justify-content: center; } }
        .col-4 {
          display: none; }
        .col-3 {
          width: 100%; } }
      .tools {
        justify-content: flex-end;
        width: 100%;
        li:nth-child(3) {
          a {
            padding-right: 9px; } }
        li:last-child {
          a {
            padding-right: 0; } }
        .burger-menu {
          display: flex; } }
      .inner-menu {
        top: 40px;
        height: calc(100vh - 100px);
        z-index: 212;
        cursor: auto;
        overflow-y: auto;
        &.active {
          opacity: 1;
          pointer-events: auto; } }
      .preview-video {
        display: none; }

      ul.menu {
        position: fixed;
        height: calc(100vh - 60px);
        top: 60px;
        width: 100vw;
        background: $black;
        left: 0;
        z-index: 211;
        flex-direction: column;
        transition: transform .75s cubic-bezier(0.8, 0, 0.21, 1);
        transform: translateX(100%);
        pointer-events: none;
        &.on {
          pointer-events: auto;
          transform: translateX(0);
          visibility: visible; }
        & > li {
          position: relative;
          cursor: pointer;
          transition: opacity .2s ease, transform .325s ease;
          height: 38px;
          &:hover {
            .inner-menu {
              opacity: 0;
              pointer-events: none;
              &.active {
                opacity: 1;
                pointer-events: auto; } } }
          &.on > a::after {
            transform: rotateZ(180deg); }
          &.hidden {
            opacity: 0;
            pointer-events: none; } }
        & > li.no-inner > a {
          &::after {
            display: none; } }
        & > li {
          & > a {
            padding: 20px 0;
            font-size: 14px;
            display: inline-block;
            font-weight: 500;
            pointer-events: none;
            &::after {
              content: "\e037";
              font-family: "javavalley";
              font-size: 16px;
              right: 23px;
              position: absolute;
              transition: transform .3s ease;
              transition-delay: .225s; } }
          &.no-inner > a {
            pointer-events: auto; } } } } } }

.tabs-menu {
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  z-index: 2;
  a {
    padding: 21px 21px 15px;
    display: block;
    color: #000;
    font-size: 14px;
    letter-spacing: .5px;
    border-bottom: solid 5px transparent;
    p {
      font-weight: 300; }
    &.active {
      border-bottom: solid 5px $primary;
      p {
        font-weight: 400; } } } }
.toolbar {
  position: fixed;
  right: 0;
  top: calc(50vh - 50px);
  z-index: 10;
  a {
    color: #fff; }
  .toolbar-item {
    position: relative;
    cursor: pointer;
    &:hover {
      .icon-wrapper {
        background: #000; }
      .text-wrapper {
        opacity: 1;
        pointer-events: auto;
        transform: none; } } }
  .icon-wrapper {
    font-size: 28px;
    padding: 11px;
    background: rgba(0,0,0,.65);
    transition: .2s ease;
    i {
      display: flex; } }
  .text-wrapper {
    position: absolute;
    right: 100%;
    top: 0;
    height: 50px;
    padding: 0 50px;
    background: #000;
    transform: translateX(100%);
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: .275s ease;
    p {
      font-size: 12px;
      letter-spacing: 2px; } }
  @media screen and (max-width: 992px) {
    display: none; } }

.line {
  display: block;
  width: 1px;
  height: 17px;
  background: #525252;
  position: relative;
  top: 10px; }
