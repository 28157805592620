#fullpage {
    .main-content {
        margin-top: 75px; }
    .swiper-slide .cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .swiper-slide .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.8);
        z-index: 1; }
    .swiper-slide ul {
        width: 100%;
        li a {
            margin-bottom: 42px;
            opacity: .7;
            & + span {
                text-transform: uppercase;
                text-align: center; }
            &:hover {
                opacity: 1; } } }
    .main-content {
        width: 100%;
        height: 100%; }

    .gallery-slide , .gallery-slide .wrap-content , .slideshow {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }

    .gallery-slide .wrap-content {
        position: relative; }

    .slideshow {
        position: absolute;
        width: 100%; }

    .slideshow img {
        display: block;
        margin: auto;
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover; }
    .slideshow p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 3rem;
        color: #fff;
        background: rgba(45, 45, 45, 0.7);
        padding: 10px; }
    .des-meetCG {
        background-color: transparent;
        color: #fff;
        z-index: 1;
        position: relative;
        margin: auto;
        height: 100%;
        width: 100%;
        max-width: 550px; }

    .des-meetCG h3 {
        font-size: 32px;
        font-weight: 300; }

    .des-meetCG p {
        line-height: 24px;
        font-size: 16px;
        font-family: "Gotham",sans-serif;
        font-weight: 300;
        text-align: left;
        margin-bottom: 16px; }
    @media screen and (max-width: 767px) {
        .section {
            position: relative; }
        &.swiper-container {
            // overflow: initial
 }            // height: initial
        .swiper-wrapper {
            display: block; }
        &.swiper-slide {
            display: block;
            margin: 24px 0; }
        .des-meetCG {
            justify-content: flex-start;
            padding: 0 16px;
            padding-bottom: 48px;
            h3 {
                margin-top: 60px; }
            ul {
                margin-bottom: 58px;
                li {
                    img {
                        width: 48px; } } } } } }
