.dropdown {
  position: relative;
  &.d-right {
    .dropdown-content {
      right: 0; } }
  &:hover {
    .dropdown-content {
      opacity: 1;
      transform: none;
      pointer-events: auto; } }
  .dropdown-content {
    position: absolute;
    top: 100%;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    opacity: 0;
    z-index: 999;
    transform: translate3d(0,-10px,0);
    pointer-events: none;
    transition: .2s ease; }
  .menu-item {
    a {
      padding: 10px 15px;
      min-width: 185px;
      &:hover {
        background: $primary;
        p, i {
          color: #fff; } } }
    p {
      color: #000; }
    img {
      width: 24px;
      height: 24px;
      margin-right: 15px; }
    i {
      color: $primary;
      display: flex; } } }
