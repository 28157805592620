.product-area {
  padding: 0 40px;
  padding-bottom: 100px;
  box-sizing: border-box;
  &.no-padding {
    padding: 0; }
  .info-product {
    width: 80%;
    margin: 0 auto;
    padding: 15px 10px;
    @media screen and (min-width: 1600px) {
      width: 70%; }
    li:last-child {
      span {
        color: #564d55;
        font-weight: 500; } }
    li span {
      font-family: $title;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: .5px;
      color: $text;
      @media screen and (min-width: 1600px) {
        font-size: 13px; }
      &:nth-child(2) {
        font-size: 8px; } }
    h3 {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 14px;
      color: #564d55;
      margin: 0;
      margin-bottom: 5px;
      @media screen and (min-width: 1600px) {
        font-size: 16px; } } }
  .product-main {
    width: calc(100% / 4);
    position: relative;
    margin-bottom: 70px; }
  .product-image {
    padding: 20px 0px 0px;
    overflow: hidden;
    @media screen and (max-width: 1024px) {
      padding: 20px 50px 0px; }
    a {
      position: relative;
      display: block;
      height: 225px;
      width: 225px; }
    img {
      position: absolute;
      display: block;
      /* height: 100% !important */
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 225px;
      margin: auto;
      // -o-object-fit: cover
      /* object-fit: cover */
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: opacity .65s ease;
      &:first-child {
        position: absolute;
        // position: relative
        max-width: 100%;
        height: auto; }
      &.inactive {
        opacity: 0; } } }
  @media screen and (max-width: 1440px) {
    .product-image {
      a {
        width: 175px;
        height: 175px; }
      img {
        max-height: 175px; } } }
  @media screen and (max-width: 1024px) {
    padding: 0 20px;
    padding-bottom: 100px;
    .product-main {
      width: calc(100%/3); }
    .product-image {
      a {
        width: calc(100vw / 3 - 70px);
        height: calc(100vw / 3 - 70px); } } }
  @media screen and (max-width: 681px) {
    padding: 0;
    padding-bottom: 100px;
    .product-main {
      width: calc(100%/2); }
    .product-image {
      a {
        width: calc(100vw / 2 - 105px);
        height: calc(100vw / 2 - 70px); } } }
  &.product-area-swatches {
    padding: 0;
    .product-main {
      margin: 0px;
      padding: 0; }
    .product-image {
      width: 100%;
      height: 100%;
      padding: 0;
      a {
        width: 100%;
        height: 100%;
        img {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 1px;
          max-height: initial;
          min-height: calc(100vw / 4);
          object-fit: cover;
          transition: none;
          opacity: 1;
          visibility: visible; } } }
    .info-product {
      text-align: center;
      margin: 0 auto;
      padding: 15px 0px;
      background: #fff;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      transform: translate3d(0,20px,0);
      transition: transform .2s, visibility .25s, opacity .25s;
      h3,h4 {
        color: #282828;
        font-size: 16px;
        margin: 0;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        letter-spacing: 1px; }
      span {
        color: #7a8184;
        display: block;
        width: 100%;
        font-size: 12px;
        letter-spacing: .5px;
        text-transform: uppercase;
        font-family: $title;
        margin-top: 8px; } }
    .product-main {
      &:hover {
        .info-product {
          visibility: visible;
          opacity: 1;
          transform: translate3d(0,0,0); } } } } }
h5.add-code {
  margin: 12px 0 1px;
  font-weight: 300;
  font-size: 11px;
  font-family: gotham;
  color: #7a8184; }
