.card-inspiration {
  width: 311px;
  height: 255px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  &.big {
    width: calc(311px * 2 + 20px);
    height: calc(255px * 2 + 20px);
    @media screen and (max-width: 1304px) {
      height: 255px; }
    @media screen and (max-width: 742px) {
      width: 311px; } }
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  .content {
    position: relative;
    z-index: 2;
    color: #fff;
    padding: 15px 20px;
    height: 100%;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(#564D55, .65);
      z-index: -1;
      opacity: 0;
      transition: .2s ease; }
    i {
      font-size: 32px;
      display: flex; }
    p {
      margin: 5px 0; }
    .wrapper {
      transform: translate3d(0,20px,0);
      opacity: 0;
      transition: .2s ease; }
    .text {
      position: absolute;
      padding: 15px 20px;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: .2s ease; } }
  &:hover {
    .content {
      &::after {
        opacity: 1; }
      .text {
        opacity: 1; }
      .wrapper {
        transform: none;
        opacity: 1; } } }
  @media screen and (max-width: 450px) {
    width: 100%;
    &.big {
      width: 100%;
      height: 255px; } } }
.card-wrapper-big {
  width: calc(100% - (311px * 2 + 40px));
  @media screen and (max-width: 1304px) {
    width: 100%;
    justify-content: center;
    .card-inspiration:first-child, .card-inspiration:last-child {
      width: calc(311px * 2 + 20px); } }
  @media screen and (max-width: 742px) {
    .card-inspiration:first-child, .card-inspiration:last-child {
      width: 311px; } }
  @media screen and (max-width: 450px) {
    width: 100%; } }
.inspiration-wrapper {
  width: calc(100% + 20px);
  margin: -10px;
  padding: 30px 0;
  @media screen and ( max-width: 1304px ) {
    padding: 30px;
    justify-content: center; } }
#inspiration-detail {
  .product-area {
    margin-top: 20px; }
  .product-main {
    button.btn {
      opacity: 0;
      width: 100%;
      padding: 14px 30px; }
    &:hover {
      button.btn {
        opacity: 1; } } }
  .text {
    padding: 20px 30px; }
  .in-style {
    margin: 30px 0; }
  h5 {
    margin: 0; }
  h2 {
    margin: 0; }
  h4 {
    font-weight: 500;
    color: #000; }
  .main-image {
    max-width: 624px;
    width: 100%;
    img {
      width: 100%; } }
  @media screen and (max-width: 768px) {
    .text {
      padding: 20px 0; }
    .mainPopup {
      margin-top: 138px;
      .content {
        height: 100%;
        padding: 20px; } }
    .product-main {
      margin-bottom: 10px;
      button.btn {
        display: none; } } } }
