$primary: #564D55;
// $primary: #A09178
$text: #7a8184;
$pink: #f5236d;
$neutral: #b2b2b2;
$black: #000;
$white: #fff;

$background: #f4f3f3;


$paragraph: "Roboto", sans-serif;
$title: "Gotham", sans-serif;
