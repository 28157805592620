.popup-slide {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    font-family: "Gotham",sans-serif;
    display: none;
    &.active {
        display: block; }
    .overlay-popup-slide {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.8); }
    button {
        font-family: "Gotham",sans-serif;
        margin-bottom: 32px; }
    .btnIcon {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #ffffff;
        z-index: 10;
        font-size: 24px; }
    .main-popup-slide {
        background-color: #fff;
        width: 100%;
        max-width: 536px;
        height: 100%;
        margin-right: 0;
        margin-left: auto;
        position: relative; }

    .header-popup-slide {
        position: relative;
        width: 100%;
        height: 180px;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            object-fit: cover; }
        h3 {
            margin: auto;
            text-align: center;
            font-size: 32px;
            font-weight: 300;
            color: #fff;
            position: relative;
            text-transform: uppercase; } }
    .menu-popup-slide {
        width: 100%;
        height: 60px;
        background-color: #fff;
        border-bottom: 1px solid rgba(0,0,0,.1);
        ul {
            li {
                padding-left: 16px;
                button {
                    background-color: transparent;
                    border: none;
                    border-bottom: 6px solid transparent;
                    padding: 20px 28px;
                    text-transform: uppercase;
                    letter-spacing: .5px;
                    cursor: pointer;
                    outline: none;
                    &.active {
                        font-weight: 500;
                        border-bottom: 6px solid #564d55; } } } } }
    .content-popup-slide {
        overflow-y: scroll;
        height: calc(100% - 240px);
        .inner-content {
            padding: 32px 16px;
            display: none;
            &.active {
                display: block; }
            .img-inner-content {
                width: 60px;
                margin-right: 16px;
                img {
                    width: 100%; } }
            ul {
                li.list-content {
                    border-bottom: 1px solid rgba(0,0,0,.1);
                    padding: 32px 0; }
                h3, h4, h5, p {
                    margin: 0;
                    line-height: 22px; }
                h3, h4 {
                    font-weight: 500; }
                h3 {
                    font-size: 18px; }
                h5, h4 {
                    font-size: 16px; }
                h4, p {
                    font-weight: 300; }
                h5 {
                    margin-top: 12px; } }
            .form-input {
                position: relative;
                select.styleOutline {
                    width: 100%;
                    font-size: 14px;
                    padding: 15px 25px;
                    border-radius: 4px;
                    font-weight: 400;
                    width: 100%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    letter-spacing: .5px;
                    border: 1px solid #d7d7d7;
                    & + .icon {
                        position: absolute;
                        top: calc(50% - 8px);
                        right: 16px; } }
                input[type="text"], input[type="number"], select, select.styled, textarea, input[type="password"] {
                    background-color: transparent;
                    border-color: #d7d7d7;
                    margin: 8px 0; }
                textarea {
                    margin-bottom: 0; }
                .upload-cover {
                    min-height: 52px;
                    background-color: #f8f8f8;
                    position: relative;
                    width: 100%;
                    border: 1px solid #d7d7d7;
                    border-top: none;
                    border-radius: 0 0 4px 4px;
                    input[type="file"] {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer; }
                    .after-upload.off, .upload-style.off {
                        display: none; }
                    .after-upload {
                        font-size: 17px;
                        color: #737579;
                        font-weight: 300;
                        max-width: 90%;
                        overflow: hidden;
                        a {
                            color: #737579;
                            line-height: 0;
                            font-size: 25px; } }
                    .upload-style {
                        color: #bcbcbc;
                        width: 100%;
                        i {
                            font-size: 25px;
                            line-height: 0;
                            margin-right: 15px; }
                        h5 {
                            font-size: .9em;
                            letter-spacing: .5px;
                            text-transform: none;
                            font-weight: 300;
                            margin: 0; } } } }
            .form-btn {
                margin-top: 16px;
                margin-bottom: 32px; } } } }




