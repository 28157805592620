.breadcrumb {
  li:last-child a {
    color: $black; }
  i.icon {
    color: $neutral;
    font-size: 10px;
    position: relative;
    top: 1px;
    margin: 0 8px; }
  a {
    font-size: 10px;
    color: $neutral;
    font-weight: 400;
    letter-spacing: .5px;
    &:hover {
      color: $text; } } }
