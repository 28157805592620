.notification {
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  transition: transform .325s ease;
  &.success {
    background: #5fce9b; }
  &.error {
    background: #dc4343; }
  &.warning {
    background: orange; }
  p {
    margin: 0;
    height: 85px;
    margin-top: -3px;
    text-align: center;
    color: white;
    font-family: $title;
    font-size: 15px;
    font-weight: 300; }
  a {
    position: absolute;
    font-size: 32px;
    top: 25px;
    right: 25px;
    color: white;
    @media screen and (max-width: 992px) {
      font-size: 18px;
      top: 31px; } } }
