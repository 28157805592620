.product-detail {
  .float-wrapper {
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 2;
    top: 0;
    box-shadow: 0 3px 8px rgba(0,0,0,.1);
    font-family: $title;
    display: none;
    &.show {
      display: block; }
    .float-header {
      font-weight: 500;
      font-size: 13px;
      letter-spacing: .75px;
      padding: 10px 20px;
      background: #000;
      color: #fff;
      border-radius: 4px 4px 0 0;
      i {
        cursor: pointer; } }
    .float-content {
      padding: 10px 20px 20px;
      border: solid 1px #e8e8e8;
      border-radius: 0 0 4px 4px;
      max-height: 160px;
      overflow-y: auto; } }
  .box-variant {
    border: solid 1px transparent;
    border-bottom: solid 2px transparent;
    display: inline-block;
    border-radius: 4px 4px 0 0;
    width: 60px;
    height: 60px;
    margin: 5px;
    padding: 5px!important;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    &:hover,  &.selected {
      border-color: #e8e8e8;
      border-bottom-color: #000;
      background: none!important;
      &::before, &::after {
        display: none; } } }

  .images {
    width: 68%;
    padding-top: 6vh;
    p {
      font-size: 13px;
      font-weight: 300;
      color: $text;
      font-family: $title; }
    img {
      width: 100%;
      max-width: 600px;
      max-height: 500px;
      padding: 2.5%;
      max-height: 500px;
      box-sizing: border-box;
      object-fit: contain;
      &[src*="img_na.jpg"] {
        max-width: 300px; } }
    @media screen and (min-width: 1681px) {
      padding-top: 5vh;
      width: 75%;
      img {
        max-width: 800px;
        max-height: 600px; } }
    @media screen and (max-width: 1024px) {
      width: 60%;
      img {
        max-width: 500px;
        max-height: 400px; } } }
  .information {
    width: 32%;
    min-height: calc(100vh - 118px);
    position: relative;
    @media screen and (min-width: 1681px) {
      width: 25%; }
    @media screen and (max-width: 1024px) {
      width: 40%; } }
  span.code {
    font-family: $title;
    color: $text;
    font-size: 13px;
    letter-spacing: .5px;
    font-weight: 300;
    text-transform: none; }
  .title {
    padding-bottom: 10px; }
  .content {
    padding: 20px 0;
    .content-detail {
      padding-bottom: 10px;
      display: none;
      &.on {
        display: block; } }
    p {
      margin: 8px 0; }
    p, .content-detail li {
      line-height: 1.56;
      font-weight: 300;
      letter-spacing: .5px;
      font-size: 13px;
      font-family: $title;
      color: $text; }
    ul.list-detail {
      p {
        text-transform: uppercase;
        font-family: $title;
        color: $black;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: .5px;
        margin: 0;
        max-width: none; }
      li {
        padding: 10px 20px; } }
    ul.point {
      margin-left: 20px;
      & > li {
        list-style-type: disc;
        padding: 2px 0; } }
    ul {
      a {
        padding: 10px 0;
        font-family: $title;
        font-size: 13px;
        text-transform: uppercase;
        color: $black;
        letter-spacing: .75px;
        font-weight: 500;
        position: relative;
        i.icon {
          transition: transform .2s ease; }
        span.code {
          margin-top: 5px; }
        &:hover {
          background: #f7f7f7;
          &::before, &::after {
            content: '';
            background: #f7f7f7;
            height: 100%;
            width: 20px;
            position: absolute; }
          &::before {
            left: -20px;
            top: 0; }
          &::after {
            right: -20px;
            top: 0; } } } } }
  .floating-action {
    position: absolute;
    width: 100%;
    bottom: 0;
    &.relative {
      position: relative; }
    @media screen and (max-height: 650px) {
      position: relative; }
    #switch-unit {
      margin-top: 1px;
      margin-left: 11px; }
    ul.price {
      padding: 20px 0;
      li {
        padding: 4px 0; }
      p {
        margin: 0;
        font-family: $title;
        letter-spacing: 1px;
        font-size: 14px;
        color: $text;
        font-weight: 400;
        &:last-child {
          font-weight: 500;
          color: $black; } } } }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    .images, .information {
      width: 100%;
      min-height: auto; }
    .images {
      img {
        object-fit: contain; } }
    .content .content-detail {
      padding-top: 10px; }
    .floating-action {
      position: relative; } }
  @media screen and (max-width: 681px) {
    .images img {
      max-width: 300px;
      max-height: 300px;
      object-fit: contain; } } }
.product-detail-2 {
  margin-top: 50px;
  margin-bottom: 100px;
  h3 {
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    margin: 3px 0; }
  h2 {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: $text; }
  .packaging-table {
    margin: 90px 0 30px;
    @media screen and (max-width: 992px) {
      overflow-x: auto; }
    table {
      border: solid 1px #cecece;
      border-spacing: 0; }
    thead th {
      font-family: $title;
      font-weight: 500;
      letter-spacing: .5px;
      padding: 25px 20px;
      font-size: 16px;
      border-right: solid 1px #cecece;
      line-height: 1.25;
      &:last-child {
        border-right: none; } }
    tbody.content {
      td {
        padding: 10px 20px;
        border-right: solid 1px #cecece;
        color: $text;
        &:last-child {
          border-right: none; }
        &.no-border {
          border-right: none; } } }
    tbody.gray {
      background: $neutral;
      td {
        text-align: center;
        padding: 10px 0;
        color: $white;
        letter-spacing: .5px;
        border-right: solid 1px #cecece;
        font-size: 14px;
        &.no-border {
          border-right: none; }
        &:last-child {
          border-right: none; } } } } }
.tab-menu {
  ul {
    border-bottom: solid 1px #ddd;
    a {
      text-transform: uppercase;
      font-family: $title;
      color: $black;
      font-weight: 400;
      font-size: 14px;
      padding: 10px 20px;
      display: inline-block;
      letter-spacing: .5px;
      border-bottom: solid 4px $primary; } } }
