.fullpage-about {
    width: 100%;
    height: initial;
    .section {
        width: 100%;
        height: 100%;
        float: left;
        &.gallery-section {
            height: calc(100vh + 40px);
            min-height: 720px;
            @media screen and (max-width: 1366px) {
                height: 100%; } } }
    .content-cgnt {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 680px;
        padding: 105px 0;
        @media screen and (max-width: 767px) {
            padding: 75px 0; }
        h3 {
            text-align: center; } }
    .main-content {
        // margin-top: 10px !important
        margin-top: 0px !important;
        @media screen and (max-width: 767px) {
            margin-top: 0px !important; } }
    .des-meetCG {
        max-width: 100% !important;
        margin-bottom: 0px !important; }
    p {
        width: 100%; } }
