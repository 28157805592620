.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1680px) {
    max-width: 1310px; }
  @media screen and (max-width: 1440px) {
    max-width: 1070px; }
  @media screen and (max-width: 1024px) {
    max-width: 992px;
    padding: 0 20px;
    box-sizing: border-box; } }
.container-wide {
  max-width: 1304px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    max-width: 992px;
    padding: 0 20px;
    box-sizing: border-box; } }
