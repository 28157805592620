.col-full {
  float: left;
  width: 100%; }
.col-half {
  float: left;
  width: 50%; }
.col-2 {
  float: left;
  width: 20%; }
.col-3 {
  float: left;
  width: 30%; }
.col-4 {
  float: left;
  width: 40%; }
