#qrcode {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0,0,0,.75)
  background-color: rgba(210,210,210,1);
  z-index: 995;
  opacity: 0;
  pointer-events: none;
  transition: all .2s ease;
  &.on {
    opacity: 1;
    pointer-events: auto; }
  .inside-block {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 768px;
    background-color: $black;
    color: $white;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      max-width: 100%; }
    .close-inside-block {
      position: absolute;
      top: 27px;
      left: 20px;
      cursor: pointer;
      z-index: 1000;
      background-color: transparent;
      border: solid 1px $white;
      color: $white;
      border-radius: 4px;
      outline: none;
      padding: 5px 10px;
      font-family: $title;
      i {
        margin-right: 4px;
        font-size: .8rem;
        vertical-align: text-top;
        position: relative;
        top: 1px; } }
    .head {
      text-align: center;
      border-bottom: 1px solid #fff;
      position: relative;
      z-index: 999;
      background-color: $black;
      span {
        padding: 28px 23px 18px;
        display: block;
        font-family: $title;
        i {
          margin-right: 10px;
          font-size: 1.5rem;
          vertical-align: text-top; } } } }
  .area-qrcode {
    margin: auto;
    width: 100%;
    position: absolute;
    // top: 76px
    top: 50px;
    right: 0;
    left: 0;
    bottom: 0;
    &:before {
      // content: ''
      // position: absolute
      // top: 1px
      // left: 0
      // right: 0
      // bottom: 0
      // width: 384px
      // height: 383px
      // background-color: #22f01b36
      // border-radius: 4px
      // z-index: 1000
      // // border: solid 1px #ffffff
      // transform: translate(50%,50%);
 } }      // pointer-events: none;
  #scan-qrcode {
    // position: absolute
    // top: 50%
    // right: 0
    // left: 0
    // bottom: 0
    // width: initial
    // height: initial
    // min-height: 100%
    // min-width: 100%
    // /* margin: 0 50%; */
    // -webkit-transform: translate(-50%, 0) !important
    // transform: translate(-50%, -50%) !important
    position: absolute;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    top: 50%;
    left: 50%;
    pointer-events: none;
    -webkit-transform: translate(-50%, -50%) scale(1.76215) !important;
    transform: translate(-50%, -50%) scale(1.76215) !important;
    @media screen and (max-width: 767px) {
      -webkit-transform: translate(-50%, -50%) scale(1) !important;
      transform: translate(-50%, -50%) scale(1) !important; } } }


#target-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: rgba(0, 0, 0, .3);
  z-index: 998;
  max-width: 768px;
  max-height: 100%;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 26%, 75% 26%, 75% 62%, 0% 62%, 25% 100%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 26%, 75% 26%, 75% 62%, 0% 62%, 25% 100%, 100% 100%, 100% 0%);
  pointer-events: none;


  @media screen and (max-width: 767px) {
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 11% 100%, 11% 27%, 90% 27%, 90% 65%, 0% 65%, 0 100%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0% 100%, 11% 100%, 11% 27%, 90% 27%, 90% 65%, 0% 65%, 0 100%, 100% 100%, 100% 0%); } }

#scanned {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: rgba(255,255,255,.6);
  z-index: 998;
  max-width: 384px;
  max-height: 395px; }
/* popup confirmation */
#conf-qr-code {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  z-index: 999;
  .popup-conf {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 360px;
    max-height: 175px;
    background-color: #ffffff;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 25px;
    border-radius: 4px;
    @media screen and (max-width: 380px) {
      max-width: 300px; }
    .head {
      font-weight: 400;
      text-transform: none;
      font-size: 28px;
      margin-bottom: 20px;
      color: #6E6E6E; }
    .box-cta {
      text-transform: capitalize;
      color: #6E6E6E;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      li {
        width: 50%;
        text-align: center;
        border: solid 1px #D8D8D8;
        border-bottom-left-radius: 4px;
        &:last-child {
          border-right: none;
          // background-color: $primary
          // border: solid 1px $primary
          // border-bottom-left-radius: 0px
          // border-bottom-right-radius: 4px
          button {
 }            // color: $white
          // &:hover
          //   background-color: $white
          //   border: solid 1px #D8D8D8
          //   button
 }          //     color: #6E6E6E

        &:hover {
          background-color: $primary;
          border: solid 1px $primary;
          button {
            color: $white; } } }
      button {
        color: #6E6E6E;
        border: none;
        font-weight: 400;
        outline: none;
        padding: 22px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: transparent; } }
    .des {
      font-family: $paragraph;
      font-weight: 400;
      span {
        color: #6E6E6E;
        max-width: 45ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        @media screen and (max-width: 380px) {
          max-width: 30ch; } } } } }

/* loading */
// #loading-box
//   position: absolute
//   top: 50px
//   right: 0
//   bottom: 0
//   left: 0
//   width: 100%
//   height: 100%
//   margin: auto
//   background-color: rgb(240, 240, 240)
//   z-index: 998
//   max-width: 768px
//   max-height: 100%
// .loading-animation
//   animation: animationFrames ease-out 1.5s
//   animation-iteration-count: infinite
//   transform-origin: 50% 50%
//   animation-fill-mode: forwards

//   -webkit-animation: animationFrames ease-out 1.5s
//   -webkit-animation-iteration-count: infinite
//   -webkit-transform-origin: 50% 50%
//   -webkit-animation-fill-mode: forwards

//   -moz-animation: animationFrames ease-out 1.5s
//   -moz-animation-iteration-count: infinite
//   -moz-transform-origin: 50% 50%
//   -moz-animation-fill-mode: forwards

//   -o-animation: animationFrames ease-out 1.5s
//   -o-animation-iteration-count: infinite
//   -o-transform-origin: 50% 50%
//   -o-animation-fill-mode: forwards

//   -ms-animation: animationFrames ease-out 1.5s
//   -ms-animation-iteration-count: infinite
//   -ms-transform-origin: 50% 50%
//   -ms-animation-fill-mode: forwards


// @keyframes animationFrames
//   0%
//     opacity: 0
//     transform: translate(100%, 0px)

//   100%
//     opacity: 1
//     transform: translate(0px, 0px)


// @-moz-keyframes animationFrames
//   0%
//     opacity: 0
//     -moz-transform: translate(100%, 0px)

//   100%
//     opacity: 1
//     -moz-transform: translate(0px, 0px)


// @-webkit-keyframes animationFrames
//   0%
//     opacity: 0
//     -webkit-transform: translate(100%, 0px)

//   100%
//     opacity: 1
//     -webkit-transform: translate(0px, 0px)


// @-o-keyframes animationFrames
//   0%
//     opacity: 0
//     -o-transform: translate(100%, 0px)

//   100%
//     opacity: 1
//     -o-transform: translate(0px, 0px)


// @-ms-keyframes animationFrames
//   0%
//     opacity: 0
//     -ms-transform: translate(100%, 0px)

//   100%
//     opacity: 1
//     -ms-transform: translate(0px, 0px)
