.popup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: .2s ease;
  &.on {
    opacity: 1;
    pointer-events: auto;
    .mainPopup {
      transform: translateY(0);
      opacity: 1; } }
  p {
    font-size: 14px;
    color: $text;
    line-height: 1.56;
    margin: 8px 0 0;
    b {
      color: $black; }
    &.center {
      text-align: center; }
    &.sub-title {
      margin-top: 0;
      font-family: $title;
      font-weight: 300;
      text-transform: uppercase;
      color: #333; } }
  .video-header {
    position: relative;
    z-index: 2;
    padding: 20px 20px;
    background: #000;
    .close-button {
      background: none;
      border: none;
      font-size: 16px;
      color: #fff;
      outline: none;
      cursor: pointer; }
    p {
      color: #fff;
      margin: 0;
      letter-spacing: .5px; } }
  #filmplayer-video {
    width: 100%;
    height: calc(100% - 62px);
    outline: none;
    border: none; }
  #by-qr {
    width: 100%;
    height: 300px;
    background: $black;
    position: relative;
    .loading-qr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $white;
      display: none; }
    .area-qrcode {
      width: 100%;
      height: 100%;
      overflow: hidden;
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scaleX(1) scale(3)!important; } } }
  .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.75);
    z-index: -1;
    // opacity: 0
    // transition: all .2s ease
    &.no-click {
      pointer-events: none; }
    i.icon {
      color: $white;
      position: absolute;
      font-size: 32px;
      right: 32px;
      top: 18px;
      cursor: pointer; } }
  .cta {
    border-top: solid 1px #ddd;

    button {
      width: 50%;
      padding: 18px;
      text-transform: uppercase;
      font-family: $title;
      background: $white;
      border: none;
      color: $text;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      cursor: pointer;
      outline: none;
      transition: all .2s ease;
      &:hover {
        background: $primary;
        color: $white; }
      &:first-child {
        border-radius: 0px 0px 0px 8px;
        border-right: solid 1px #ddd; }
      &:last-child {
        border-radius: 0px 0px 8px 0px; } }
    &.full {
      button {
        width: 100%;
        border-radius: 0 0 8px 8px;
        border: none; } } }
  .mainPopup {
    max-width: 450px;
    box-sizing: border-box;
    width: 100%;
    transform: translateY(50px);
    opacity: 0;
    transition: all .325s ease;
    &.small {
      max-width: 400px; }
    &.big {
      max-width: 850px;
      max-height: 95vh;
      border-radius: 8px;
      overflow: hidden; }
    &.xl {
      max-width: 1167px;
      max-height: 95vh;
      border-radius: 8px;
      overflow: hidden; }
    .header {
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      position: relative;
      top: 10px;
      img {
        width: 100%; } }
    h4 {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 1px;
      color: $text;
      font-family: $title;
      margin: 0; }
    h5 {
      font-family: $title;
      color: $text;
      font-weight: 400;
      margin: 8px 0; }
    h2 {
      font-weight: 300;
      text-transform: none;
      font-size: 28px;

      &.center {
        text-align: center;
        line-height: 1.56;
        margin-bottom: 15px; }
      &.no-margin {
        margin-bottom: 0; } }
    .content {
      background: $white;
      border-radius: 8px 8px 0 0;
      padding: 35px 35px 20px;
      max-height: calc(100vh - 145px);
      overflow-y: auto;
      box-sizing: border-box; }
    .images {
      margin: 25px 0;
      img {
        max-width: 70%;
        width: 100%;
        height: 100%;
        object-fit: contain; } } }
  @media screen and (max-width: 681px) {
    // align-items: flex-start
    // overflow-y: auto
    .mainPopup {
      max-width: none;
      min-height: 100vh;
      // margin-top: 60px
      .content {
        border-radius: 0;
        height: calc(70vh + 27px);
        overflow-y: auto;
 }        // height: calc(100vh - 108px)
      .cta button {
        border-radius: 0; }
      &.small {
        // justify-content: center
        .content {
          height: auto; }
        .cta button {
          border-radius: 0 0 8px 8px; } } } } }
.detail-information {
  max-width: 90%;
  margin: 30px auto;
  border-bottom: solid 1px #ddd;
  padding-bottom: 30px;
  @media screen and (max-width: 681px) {
    max-width: 100%;
    .table-holder {
      flex-direction: column;
      & > .col12 {
        width: 100%!important; }
      .col12:first-child {
        dt[style] {
          text-align: center;
          margin-bottom: 50px; } } }
    & > .f.f-r {
      flex-direction: column; }
    & > .f.f-r.mdl {
      flex-direction: column; }
    & > h4 {
      margin-bottom: 20px!important; }
    span.label {
      width: 100%;
      margin-bottom: 5px; }
    span.label.t {
      top: 0; }
    input {
      margin: 3px 0 15px; } } }
.select-folder {
  margin-top: 20px;
  ul {
    height: 390px;
    background: #f8f8f8;
    overflow-y: auto;
    margin-top: 10px; }
  li {
    width: 50%; }
  a {
    padding: 20px 0;
    &:hover {
      background: #eee; }
    .ico {
      width: 100px;
      i {
        font-size: 32px;
        color: $primary; } }
    p {
      margin: 0; }
    p.bold {
      font-weight: 500;
      color: #333;
      font-family: $title; } } }
.popup-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0,0,0,.8);
  z-index: 9999;
  &.active {
    display: flex; }
  .close-overlay {
    cursor: pointer;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    @media screen and (max-width: 992px) {
      z-index: 3;
      pointer-events: none;
      i {
        pointer-events: auto; } }
    i {
      font-size: 32px;
      position: absolute;
      right: 52px;
      top: 30px;
      cursor: pointer;
      color: #fff; } }
  .popup-box-container {
    width: 100%;
    max-width: 1070px;
    height: 100%;
    max-height: 640px;
    z-index: 2;
    margin: auto;
    // overflow-x: hidden
    @media screen and (max-width: 992px) {
      overflow-y: auto; }
    .popup-box-content-left {
      width: 60%;
      background-color: #000;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    .popup-box-content-right {
      width: 40%;
      padding: 32px 40px;
      background-color: #ffffff;
      h3,h4 {
        margin: 0; }
      h3 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 24px; }
      h4 {
        font-size: 14px;
        font-weight: 300; }
      ul {
        margin-top: 16px;
        span {
          display: block;
 }          // margin-top: 16px
        li {
          padding: 2px 0;
          p {
            margin: 0; }
          ul {
            margin-top: 8px;
            li {
              display: inline-block;
              padding: 2px 0; }
            img {
              width: 35px;
              float: left;
              display: block;
              margin-right: 8px; }
            p {
              width: calc(100% - 43px);
              float: left;
              margin: 0;
              font-size: 12px; } } } }
      p {
        font-family: "roboto", sans-serif;
        font-size: 14px;
        font-weight: 300;
        margin-top: 16px;
        line-height: 1.5;
        letter-spacing: .5px;
        color: #282828; }
      .popup-box-inner-child {
        display: none;
        &.active {
          display: block; } } }
    @media screen and (max-width: 992px) {
      max-height: 100%;
      & > .f-r {
        flex-direction: column; }
      .popup-box-content-left {
        width: 100%;
        height: 30vh; }
      .popup-box-content-right {
        width: 100%;
        min-height: 70vh; } } } }
