.comment {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
  &.on {
    pointer-events: auto;
    opacity: 1;
    .overlay {
      opacity: 1; }
    .comment-section {
      transform: translateX(0); } }
  a#close-comment {
    font-size: 22px;
    color: $black;
    position: relative;
    top: 2px; }
  .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.75);
    z-index: -1;
    opacity: 0;
    transition: opacity .325s ease;
    transition-delay: .325s; }
  .comment-section {
    width: 450px;
    position: relative;
    left: calc(100% - 450px);
    height: 100vh;
    background: $white;
    box-sizing: border-box;
    padding: 35px 0;
    overflow: hidden;
    transform: translateX(100%);
    transition: all .785s cubic-bezier(0.82, 0.2, 0.25, 1);

    ul#comment-container {
      height: calc(100vh - 184px);
      overflow-y: scroll;
      border-bottom: solid 1px #ddd;
      margin-right: -17px; }
    .relative {
      position: relative; }
    .action {
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      bottom: 22px;
      button {
        position: absolute;
        top: 13px;
        right: 7px;
        background: none;
        border: none;
        font-size: 20px;
        color: $text;
        cursor: pointer;
        outline: none; } }
    input[type="text"] {
      width: 100%;
      padding: 12px;
      padding-right: 45px;
      margin: 0;
      box-sizing: border-box;
      border-radius: 8px;
      height: 50px;
      border: none;
      font-weight: 400;
      font-size: 15px;
      background: #f8f8f8;
      font-family: $paragraph; } }
  h2 {
    font-weight: 300;
    text-transform: none;
    font-size: 28px;
    margin-top: 0; }
  ul {
    padding: 0 35px; }
  .section {
    padding: 0 35px; }
  .list-comment {
    padding: 20px 25px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 15px 0;
    p {
      font-size: 14px;
      line-height: 1.56;
      color: #333;
      margin: 10px 0;
      margin-bottom: 0;
      word-wrap: break-word;
      a {
        color: #00ace2;
        text-decoration: underline; } } }
  span.name {
    font-weight: 500;
    font-family: $title; }
  span.time {
    font-size: 12px;
    color: $text;
    margin-left: 8px; }
  @media screen and (max-width: 992px) {
    .comment-section {
      width: 100%;
      left: 0;
      padding-top: 20px;
      .action {
        padding: 21px 15px;
        bottom: 0;
        position: inherit;
        button {
          padding: 0;
 } }          // top: 38px
      ul#comment-container {
        margin-right: 0;
        height: calc(100vh - 155px); } }
    span.name {
      font-size: 14px; }
    ul {
      padding: 0 15px; }
    .list-comment p {
      font-size: 13px; }
    h2 {
      font-size: 22px; } } }
