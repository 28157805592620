.banner-main {
  height: 299px;
  overflow: hidden;
  position: relative;
  background: $black;
  .title {
    z-index: 2;
    margin-top: 70px;
    h2 {
      color: rgba($white, .9);
      font-size: 2.25em;
      font-weight: 200;
      span {
        font-weight: 300;
        letter-spacing: .5px; } } }
  .image-bg {
    background: #000;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
      pointer-events: none;
      transition: opacity 2s ease;
      &.inactive {
        opacity: 0; } } }
  @media screen and (max-width: 992px) {
    height: 240px;
    .title {
      margin-top: 70px;
      h2 {
        font-size: 1.75em;
        line-height: 1.3; } } }
  @media screen and (max-width: 681px) {
    .title {
      margin-top: 60px; } } }
