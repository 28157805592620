@font-face {
  font-family: "Gotham";
  src: url("fonts/gotham-bold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/gotham-bold-webfont.woff") format("woff"), url("fonts/gotham-bold-webfont.ttf") format("truetype"), url("fonts/gotham-bold-webfont.svg#javavalley") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/gotham-black-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/gotham-black-webfont.woff") format("woff"), url("fonts/gotham-black-webfont.ttf") format("truetype"), url("fonts/gotham-black-webfont.svg#javavalley") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/gotham-medium-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/gotham-medium-webfont.woff") format("woff"), url("fonts/gotham-medium-webfont.ttf") format("truetype"), url("fonts/gotham-medium-webfont.svg#javavalley") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/gotham-book-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/gotham-book-webfont.woff") format("woff"), url("fonts/gotham-book-webfont.ttf") format("truetype"), url("fonts/gotham-book-webfont.svg#javavalley") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Gotham";
  src: url("fonts/gotham-light-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/gotham-light-webfont.woff") format("woff"), url("fonts/gotham-light-webfont.ttf") format("truetype"), url("fonts/gotham-light-webfont.svg#javavalley") format("svg");
  font-weight: 200;
  font-style: normal; }
