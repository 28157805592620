footer {
  background: $black;
  color: $white;
  padding: 50px 0px 85px;
  border-top: solid 5px $primary;
  &.small {
    padding: 30px 0;
    border-top: solid 2px $primary;
    .copyright {
      margin: 0;
      text-transform: none;
      letter-spacing: .5px;
      font-size: 12px;
      font-weight: 300; } }
  .container > .f.f-c:first-child {
    @media screen and (max-width: 992px) {
      width: 100%; }
    @media screen and (max-width: 681px) {
      margin-bottom: 30px; } }
  .copyright {
    font-size: 10px;
    text-transform: uppercase;
    font-family: $title;
    letter-spacing: 1px;
    margin-top: 15px;
    color: $neutral; }
  a.copyright {
    &:hover {
      color: #fff; } }
  .social-media {
    margin-top: 100px;
    li {
      padding: 0 12px;
      &:first-child {
        padding-left: 0; }
      &.login {
        border-left: solid 1px #888;
        margin-left: 10px;
        padding-left: 20px; } }

    a {
      color: $neutral;
      font-size: 22px;
      span {
        font-size: 14px;
        margin-left: 10px;
        font-weight: 500; }
      i {
        display: flex; }
      &:hover {
        color: $white; } } }
  input[type="text"] {
    width: 280px;
    color: $white;
    height: 44px;
    line-height: 34px;
    border-radius: 4px;
    padding: 0 20px;
    font-size: 1em;
    border: none;
    background: rgba(255,255,255,.1);
    &::-webkit-input-placeholder {
      font-size: 14px;
      color: rgba(255,255,255,.5); }

    &:-moz-placeholder {
      font-size: 14px;
      color: rgba(255,255,255,.5); }

    &::-moz-placeholder {
      font-size: 14px;
      color: rgba(255,255,255,.5); }

    &:-ms-input-placeholder {
      font-size: 14px;
      color: rgba(255,255,255,.5); } }

  .footer-menu {
    span.flag {
      height: 20px;
      width: 20px;
      margin: 0;
      margin-right: 10px;
      img {
        width: 100%; } }
    li {
      padding: 2px 0; }
    form > a {
      font-size: 16px;
      padding: 5px; }
    span {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: 500;
      font-family: $title;
      margin-bottom: 15px;
      letter-spacing: 2px;
      display: block; }
    li:last-child {
      padding-bottom: 5px; }
    a {
      color: $neutral;
      font-size: 12px;
      letter-spacing: .5px;
      &:hover {
        color: #fff; } } }
  @media screen and (max-width: 681px) {
    padding-bottom: 30px;
    .social-media {
      margin-top: 50px;
      ul {
        justify-content: center; } }
    .copyright {
      text-align: center; }
    .secondBox {
      width: 100%;
      margin-top: 50px; } } }
